<template>
  <v-dialog
    :value="showInstruction || showCourierRule"
    max-width="1200px"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <v-card tile>
      <v-card-title class="text-h6 white--text px-2 py-1 primary justify-space-between">
        <v-spacer></v-spacer>
        {{ !showCourierRule ? `${$route.name}画面マニュアル` : '各クーリエルール' }}
        <v-spacer></v-spacer>
        <v-btn text icon color="white" @click="handleClose">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-actions v-if="pdfData" class="d-flex justify-center pa-0 ma-0">
        <v-col cols="12" sm="10" class="pa-0" :key="pdfUrl">
          <pdf v-for="page in pdfNumPages" :src="pdfData" :page="page ? page : ''" :key="page">
            <template slot="loading">
              <div class="d-flex justify-center">
                <v-progress-linear v-if="page == 1" class="mt-4" color="primary" indeterminate></v-progress-linear>
              </div>
            </template>
          </pdf>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import pdf from 'pdfvuer';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
export default {
  components: {
    pdf,
  },
  emits: ['close'],
  data() {
    return {
      pdfUrl: null,
      pdfData: null,
      pdfNumPages: 1,
      prevPathName: '',
    };
  },
  computed: {
    ...mapGetters({
      showInstruction: 'ui/showInstruction',
      showCourierRule: 'ui/showCourierRule',
      pathName: 'defined/pathNames',
      pdfFileName: 'defined/pdfFileNames',
    }),
    pdfManual() {
      switch (this.$route.name) {
        case this.pathName.KONPOMEISAI:
          return this.pdfFileName.KONPOMEISAI;
        case this.pathName.SEIYAKU_ANKEN_KAKUNIN:
          return this.pdfFileName.SEIYAKU_ANKEN_KAKUNIN;
        case this.pathName.YUUSENJUNI_SAKUSEI:
          return this.pdfFileName.YUUSENJUNI_SAKUSEI;
        case this.pathName.SHUKKA_ANKEN_KAKUTEI:
          return this.pdfFileName.SHUKKA_ANKEN_KAKUTEI;
        case this.pathName.SHUKKA_SHORUI_SAKUSEI:
          return this.pdfFileName.SHUKKA_SHORUI_SAKUSEI;
        case this.pathName.SHUKKA_JUNBI_HIKIWATASHI:
          return this.pdfFileName.SHUKKA_JUNBI_HIKIWATASHI;
        case this.pathName.SHUKKA_ZUMI_ANKEN:
          return this.pdfFileName.SHUKKA_ZUMI_ANKEN;

        default:
          return '';
      }
    },
  },
  watch: {
    showInstruction(val) {
      if (!val) {
        this.prevPathName = this.$route.name;
      } else this.pdfData && this.prevPathName === this.$route.name ? void 0 : this.getPdfData();
    },
  },
  mounted() {
    this.getPdfData();
  },
  methods: {
    ...mapActions({
      setShowInstruction: 'ui/setShowInstruction',
      setShowCourierRule: 'ui/setShowCourierRule',
      getHeader: 'api/setHeader',
    }),
    handleClose() {
      this.setShowInstruction(false);
      this.setShowCourierRule(false);
    },
    async getPdfData() {
      const url = process.env.VUE_APP_API_URL + 'get-instruction-manual';
      const body = {
        key: this.showCourierRule ? this.pdfFileName.COURIER_RULES : this.pdfManual,
      };
      const headers = await this.getHeader();
      try {
        const response = await axios.post(url, body, headers);
        this.pdfUrl = response.data;
        this.pdfData = pdf.createLoadingTask(this.pdfUrl);
        this.pdfData.then((result) => {
          this.pdfNumPages = result.numPages;
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
.v-tab-active-custom {
  background: #b1d1eb;
}
</style>
